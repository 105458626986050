import * as React from 'react';
import styled from 'styled-components';
import { Image } from './partials/Image';

interface IBrokerItem {
  name?: string;
  title?: string;
  office?: string;
  path: string;
  image?: string;
}

export const BrokerListItem: React.FC<IBrokerItem> = React.memo(
  ({ path, name, image, title, office }) => {
    return (
      <StyledBrokerItem as="a" href={path}>
        {image ? (
          <Image className="broker-image" size="medium" url={image} />
        ) : null}
        <StyledBrokerItemContent>
          {name ? <span className="name">{name}</span> : null}
          {title ? <span className="title">{title}</span> : null}
          {office ? (
            <span className="office">PrivatMegleren {office}</span>
          ) : null}
        </StyledBrokerItemContent>
      </StyledBrokerItem>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.image === nextProps.image) {
      return true;
    }
    return false;
  }
);

const StyledBrokerItem = styled.div`
  transition: background 0.15s ease-in-out;
  display: flex;
  padding: 1.25rem;
  margin-bottom: 20px;
  background: rgb(20, 20, 20);
  float: left;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  .broker-image {
    @media all and (max-width: ${(props) => props.theme.mediaSizes.large}px) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
    background: rgb(30, 30, 30);
  }
`;

const StyledBrokerItemContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 1.25rem;

  @media all and (max-width: ${(props) => props.theme.mediaSizes.large}px) {
    padding: 0;
  }

  span {
    &.name {
      font-size: 1.3125rem;
      color: ${(props) => props.theme.colors.primary};

      @media all and (max-width: ${(props) => props.theme.mediaSizes.large}px) {
        font-size: 1.125rem;
      }
    }
    &.title {
      color: white;
    }
    &.office {
      color: white;
    }
  }
`;

export const useReferrerAndUrl = () => {
  let obj = {
    referrer: '',
    url: ''
  };
  if (typeof window !== 'undefined') {
    obj.referrer = document.referrer || 'direct';
    obj.url = window.location.href;

    if (window?.info?.referrer && window.info.referrer !== '') {
      obj.referrer = window.info.referrer;
    }
  }
  return obj;
};

import { gql } from '@apollo/client';

export const GET_CONSENTS = gql`
  query GetConsentsByIds($ids: [Int]) {
    getAvailableSamtykkerByIds(ids: $ids) {
      id
      samtykketekst
    }
  }
`;
